import { AllPages, SinglePage } from './queries/pages';
import { DatabaseImplementation, DocumentationMenuItemModel, SettingModel, Status } from '../database';
import { AllArticles, SingleArticle } from './queries/articles';
import { AllSettings } from './queries/settings';
import {
    mapToArticleModel,
    mapToAuthorModel,
    mapToCategoryModel,
    mapToDocumentationMenuItemModel,
    mapToDocumentationModel,
    mapToPageModel,
    mapToSettingModel,
} from '../mappers';
import { AllAuthors, SingleAuthor } from './queries/authors';
import { AllCategories, SingleCategory } from './queries/categories';
import { AllDocumentations, DocumentationMenuItems, SingleDocumentation } from './queries/documentations';

class Hygraph implements DatabaseImplementation {
    private readonly hygraphEndpoint: string = process.env.REACT_APP_HYGRAPH_ENDPOINT || '';

    constructor(private readonly fetch: any) {
        console.log('Database implementation - ', 'Hygraph', this.hygraphEndpoint);
    }

    /* --- Setting (global website settings) --- */

    async getSetting(status: Status): Promise<SettingModel> {
        console.log(AllSettings(status));
        const allSetting: any = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: AllSettings(status),
            }),
        }).then((res: any) => res.json());
        console.log(allSetting);
        return this.mapToSettingModel(allSetting.data.settings[0]);
    }

    /* --- Documentations (doc) --- */

    async getDocumentations(status: Status) {
        console.log(AllDocumentations(status));
        const allDocumentations: any = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: AllDocumentations(status),
            }),
        }).then((res: any) => res.json());
        console.log(allDocumentations);
        return allDocumentations.data.documentations.map((documentation: any) =>
            this.mapToDocumentationModel(documentation),
        );
    }

    async getDocumentationBySlug(path: string, status: Status) {
        const { documentation } = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: SingleDocumentation(status),
                variables: { path: path },
            }),
        })
            .then((res: any) => res.json())
            .then((res: any) => res.data);
        return this.mapToDocumentationModel(documentation);
    }

    async getDocumentationMenuItems(status: Status, locale: string): Promise<DocumentationMenuItemModel[]> {
        console.log(DocumentationMenuItems(status, locale));
        const allMenuItems: any = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: DocumentationMenuItems(status, locale),
            }),
        }).then((res: any) => res.json());
        console.log(allMenuItems);
        return allMenuItems.data.documentations.map((documentation: any) =>
            this.mapToDocumentationMenuItemModel(documentation),
        );
    }

    /* --- Articles (blog) --- */

    async getArticles(status: Status) {
        console.log(AllArticles(status));
        const allArticles: any = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: AllArticles(status),
            }),
        }).then((res: any) => res.json());
        console.log(allArticles);
        return allArticles.data.articles.map((article: any) => this.mapToArticleModel(article));
    }

    async getArticleBySlug(path: string, status: Status) {
        const { article } = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: SingleArticle(status),
                variables: { path: path },
            }),
        })
            .then((res: any) => res.json())
            .then((res: any) => res.data);
        return this.mapToArticleModel(article);
    }

    /* --- Authors (blog) --- */

    async getAuthors(status: Status) {
        console.log(AllAuthors(status));
        const allAuthors: any = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: AllAuthors(status),
            }),
        }).then((res: any) => res.json());
        console.log(allAuthors);
        return allAuthors.data.authors.map((author: any) => this.mapToAuthorModel(author));
    }

    async getAuthorBySlug(path: string, status: Status) {
        const { author } = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: SingleAuthor(status),
                variables: { path: path },
            }),
        })
            .then((res: any) => res.json())
            .then((res: any) => res.data);
        return this.mapToAuthorModel(author);
    }

    /* --- Categories (blog) --- */

    async getCategories(status: Status) {
        console.log(AllCategories(status));
        const allCategories: any = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: AllCategories(status),
            }),
        }).then((res: any) => res.json());
        console.log(allCategories);
        return allCategories.data.categories.map((category: any) => this.mapToCategoryModel(category));
    }

    async getCategoryBySlug(path: string, status: Status) {
        const { category } = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: SingleCategory(status),
                variables: { path: path },
            }),
        })
            .then((res: any) => res.json())
            .then((res: any) => res.data);
        return this.mapToCategoryModel(category);
    }

    /* --- Pages (landing) --- */

    async getPages(status: Status) {
        console.log(AllPages(status));
        const allPages: any = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: AllPages(status),
            }),
        }).then((res: any) => res.json());
        console.log(allPages);
        return allPages.data.pages.map((page: any) => this.mapToPageModel(page));
    }

    async getPageBySlug(path: string, status: Status) {
        const { page } = await this.fetch(this.hygraphEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: SinglePage(status),
                variables: { path: path },
            }),
        })
            .then((res: any) => res.json())
            .then((res: any) => res.data);
        return this.mapToPageModel(page);
    }

    /* --- Mapping functions --- */

    private mapToPageModel(page: any) {
        return mapToPageModel(page);
    }

    private mapToDocumentationModel(documentation: any) {
        return mapToDocumentationModel(documentation);
    }

    private mapToDocumentationMenuItemModel(documentation: any) {
        return mapToDocumentationMenuItemModel(documentation);
    }

    private mapToArticleModel(article: any) {
        return mapToArticleModel(article);
    }

    private mapToAuthorModel(author: any) {
        return mapToAuthorModel(author);
    }

    private mapToCategoryModel(category: any) {
        return mapToCategoryModel(category);
    }

    private mapToSettingModel(setting: any) {
        return mapToSettingModel(setting);
    }
}

export default Hygraph;
