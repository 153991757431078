import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="rmc-bg-secondary-2 p-6 max-lg:p-0">
            <div className={'flex gap-2 p-8 rmc-bg-base-1 rounded-3xl max-lg:rounded-none max-md:flex-wrap'}>
                <div
                    className={'flex flex-col w-1/3 gap-2 max-md:w-full max-md:justify-around items-start max-md:mb-4'}
                >
                    <img loading="lazy" src={'/Logo_Uniqrate-White.svg'} alt={'Uniqrate logo'} className={'h-[32px]'} />
                    <p className={'text-[13px] rmc-text-base-2 leading-5'}>Rate, Comment, Engage</p>
                </div>
                <div className={'flex flex-col w-1/3 gap-2 max-md:w-[45%] max-md:mr-4'}>
                    <p className={'rmc-text-base-2 leading-[25.6px] font-medium'}>PRODUCT</p>
                    {/* <Link to={''} className="font-medium leading-[25.6px] text-white hover:underline">
                        Bloggers
                    </Link>
                    <Link to={''} className="font-medium leading-[25.6px] text-white hover:underline">
                        Technical Writers
                    </Link>
                    <Link to={''} className="font-medium leading-[25.6px] text-white hover:underline">
                        AI Content Writers
                    </Link> */}
                    <Link to={'/pricing'} className="font-medium leading-[25.6px] text-white hover:underline">
                        Pricing
                    </Link>
                </div>
                <div className={'flex flex-col w-1/3 gap-2 max-md:w-[45%]'}>
                    <p className={'rmc-text-base-2 leading-[25.6px] font-medium'}>COMPANY</p>
                    <Link to={'/blog'} className="font-medium leading-[25.6px] text-white  hover:underline">
                        Blog
                    </Link>
                    <Link
                        target={'_blank'}
                        className="font-medium leading-[25.6px] text-white  hover:underline"
                        to={'https://climate.stripe.com/icSMy1'}
                    >
                        Fight for the Climate
                    </Link>
                    {/*<Link to={''} className="font-medium leading-[25.6px] text-white hover:underline">
                        Legal mentions
                    </Link>
                    <Link to={''} className="font-medium leading-[25.6px] text-white hover:underline">
                        Privacy Policy
                    </Link>
                    <Link to={''} className="font-medium leading-[25.6px] text-white hover:underline">
                        Terms of service
                    </Link>*/}
                </div>
                <div className={'flex flex-col w-1/3 gap-2 max-md:w-[45%]'}>
                    <p className={'rmc-text-base-2 leading-[25.6px] font-medium'}>MORE</p>
                    <Link
                        to={'https://threemakers.io/'}
                        target={'_blank'}
                        className="font-medium leading-[25.6px] text-white  hover:underline"
                    >
                        3Makers
                    </Link>
                    <Link
                        to={'https://notifizz.com/'}
                        target={'_blank'}
                        className="font-medium leading-[25.6px] text-white  hover:underline"
                    >
                        Notifizz
                    </Link>
                    <Link
                        to={'https://haven.markets/'}
                        target={'_blank'}
                        className="font-medium leading-[25.6px] text-white  hover:underline"
                    >
                        Haven.markets
                    </Link>
                </div>
            </div>
        </footer>
    );
}
