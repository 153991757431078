import { Status } from '../../database';

export const AllAuthors = (status = Status.PUBLISHED) => `
    query Authors {
      authors ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} {
        firstname
        lastname
        twitterAccount
        avatar
        role
        path
        bio
        coveredLocale
      }
    }
`;

export const SingleAuthor = (status = Status.PUBLISHED) => `
    query Author {
      author ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} (where: {path: $path}) {
        firstname
        lastname
        twitterAccount
        avatar
        role
        bio
        path
        coveredLocale
      }
    }
`;
