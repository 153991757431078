import { Status } from '../../database';

export const AllPages = (status = Status.PUBLISHED) => `
    query Pages {
      pages ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} {
        coveredLocale
        createdAt
        id
        path
        publishedAt
        ref
        seo
        updatedAt
        sections(first: 40) {
          ... on Section {
            id
            type
            properties
          }
        }
      }
    }
`;

export const SinglePage = (status = Status.PUBLISHED) => `
    query Page {
      page ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} (where: {path: $path}) {
        coveredLocale
        createdAt
        id
        path
        publishedAt
        ref
        updatedAt
        sections(first: 40) {
          ... on Section {
            id
            type
            properties
          }
        }
      }
    }
`;
