import { Status } from '../../database';

export const AllCategories = (status = Status.PUBLISHED) => `
    query Categories {
      categories ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} {
        name
        path
        description
        coveredLocale
      }
    }
`;

export const SingleCategory = (status = Status.PUBLISHED) => `
    query Category {
      category ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} (where: {path: $path}) {
        name
        path
        description
        coveredLocale
      }
    }
`;
