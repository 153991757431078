import { Status } from '../../database';

export const DocumentationMenuItems = (status = Status.PUBLISHED, locale: string) => {
    let query = `where: {parentDocumentation: null, coveredLocale: "${locale}"}`;
    if (status === Status.DRAFT) {
        query += `, stage: DRAFT`;
    }
    return ` 
        query Documentations {
              documentations (${query}) {
                path
                coveredLocale
                title
                childDocumentations (first: 40) {
                  ... on Documentation {
                    path
                    coveredLocale
                    title
                    childDocumentations (first: 40) {
                      ... on Documentation {
                        path
                        coveredLocale
                        title
                        childDocumentations (first: 40) {
                          ... on Documentation {
                            path
                            coveredLocale
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
    `;
};

export const AllDocumentations = (status = Status.PUBLISHED) => ` 
    query Documentations {
      documentations ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} { 
        ref
        path
        coveredLocale
        createdAt
        id
        publishedAt
        updatedAt
        title
        content
        parentDocumentation {
            id
            title
            path
        }
      }
    }
`;

export const SingleDocumentation = (status = Status.PUBLISHED) => `
    query Documentation {
      documentation ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} (where: {path: $path}) {
        ref
        path
        coveredLocale
        createdAt
        id
        publishedAt
        updatedAt
        title
        content
        parentDocumentation {
            id
            title
            path
        }
      }
    }
`;
