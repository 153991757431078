import { Status } from '../../database';

export const AllArticles = (status = Status.PUBLISHED) => `
    query Articles {
      articles ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} {
        ref
        path
        coveredLocale
        createdAt
        id
        publishedAt
        seo
        updatedAt
        title
        description
        content
        image
        category {
            name
            path
            coveredLocale
        }
        author {
            firstname
            lastname
            twitterAccount
            avatar
            role
            path
            coveredLocale
        }
      }
    }
`;

export const SingleArticle = (status = Status.PUBLISHED) => `
    query Article {
      article ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} (where: {path: $path}) {
        coveredLocale
        createdAt
        id
        path
        publishedAt
        ref
        seo
        updatedAt
        title
        description
        content
        image
        category {
            name
            path
            coveredLocale
        }
        author {
            firstname
            lastname
            twitterAccount
            avatar
            role
            path
            coveredLocale
        }
      }
    }
`;
