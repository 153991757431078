import { Status } from '../../database';

export const AllSettings = (status = Status.PUBLISHED) => `
    query Settings {
      settings ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} {
        siteName
        siteLogo
        blogName
        githubProfile
        blogDescription
        defaultLocale
        blogEnable
        docEnable
        blogSectionsBefore(first: 40) {
          ... on Section {
            id
            type
            properties
          }
        }
        blogSectionsAfter(first: 40) {
          ... on Section {
            id
            type
            properties
          }
        }
        docSectionsBefore(first: 40) {
          ... on Section {
            id
            type
            properties
          }
        }
        docSectionsAfter(first: 40) {
          ... on Section {
            id
            type
            properties
          }
        }
      }
    }
`;
